@font-face {
  font-family: Gilroy;
  font-weight: 700;
  src: url('./assets/fonts/Radomir Tinkov-Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Gilroy;
  font-weight: 500;
  src: url('./assets/fonts/Radomir Tinkov-Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Gilroy;
  font-weight: 600;
  src: url('./assets/fonts/Radomir Tinkov-Gilroy-SemiBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Vispa-Iconfont';
  src: url('./assets/fonts/Vispa-Iconfont.eot');
  src: url('./assets/fonts/Vispa-Iconfont.eot') format('embedded-opentype'),
    url('./assets/fonts/Vispa-Iconfont.ttf') format('truetype'),
    url('./assets/fonts/Vispa-Iconfont.woff') format('woff'),
    url('./assets/fonts/Vispa-Iconfont.svg#Vispa-Iconfont') format('svg');
  font-weight: 600;
  font-display: block;
}

::selection {
  background: #606ee0;
}

body {
  font: 18px/21px 'Gilroy';
  font-weight: 600;
  color: #474747;
  background-color: #ffffff;
  width: auto;
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

h2 {
  text-align: left;
  font: 700 32px/38px 'Gilroy';
  font-weight: 700;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

h3 {
  text-align: left;
  font: 700 20px/24px 'Gilroy';
  font-weight: 700;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

h4 {
  text-align: left;
  font: 600 16px/19px 'Gilroy';
  font-weight: 600;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.webgl-container{
  width: 100vw;
  height: 100vh;
}